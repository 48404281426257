/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC323P
 * 화면 설명: 배양현황 - TS결과
 * 작 성 일: 2022.06.21
 * 작 성 자: sh.park2022
 */
 <template>
  <ur-page-container :show-title="true" title="TS결과" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">

        <!-- 상단고정 Tab UI -->
        <div class="ns-sliding-topbar">
          <mo-collapsing-header-view headerColor="#fff">
            <template #fixed="{}">
              <div class="ns-ts-info">
                <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-round-btn">
                  <mo-button class="ns-btn-round" @click="fn_Open321P" v-is-enable="{acl: ['I']}">TS 추가 신청</mo-button>
                </ur-box-container>
                
                <div class="ts-final-box">
                  <p class="ts-title">최종평가</p>
                  <div class="ts-info-box">
                    <span>평가자<span class="ml20" v-show="totLength > 0">{{ tsRsltSelInsVO.tsEvalr }}({{ tsRsltSelInsVO.cnsltEno }})</span></span>
                    <em>|</em>
                    <span class="txtBlue"><b>{{ tsRsltSelInsVO.tsResult ? '합격' : '불합격' }}</b></span>
                  </div>
                  <div class="ts-info-box">
                    <span>{{ tsRsltSelInsVO.tsLstEval }}</span><em>|</em>
                    <span>TS 실시일<span class="ml20">{{ $bizUtil.dateDashFormat(tsRsltSelInsVO.tsEfYmd) }}</span></span>
                  </div>
                </div>
                <div class="ts-reply-box">
                  <p class="ts-title">답변내용</p>
                  <span class="ts-txt">점수는 단순참고용입니다.(합격여부 무관)</span>
                  <span class="score">합계 <span class="txtBlue">{{ tsRsltSelInsVO.totNum?tsRsltSelInsVO.totNum : '0' }}점</span></span>
                </div>
              </div>
              
            </template>

            <!-- collapsing 영역 -->
            <template #collapsing>
            </template>

            <!-- stickey 영역 -->
            <template #scroll>
              <ur-box-container v-show="totLength > 0" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-interview-result">
                <div class="interview-result-area">
                  <ul class="interview-list" v-for="(item, idx) in tsRsltSelInsVO.tsResult1" :key="item.prgSeq">
                    <li class="interview-item">
                      <div class="index">{{ `Q${idx + 1}.` }}</div>
                      <div class="interview-item1">
                        <span class="qus">{{ item.questCntnt }}</span>
                        <span class="ans">{{ item.obtAnswCntnt }} | {{ item.obtAnsw }}점</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="interview-result-area">
                  <div class="interview-ttl">체크사항</div>
                  <ul class="interview-list" v-for="(item, idx) in tsRsltSelInsVO.tsResult2" :key="item.prgSeq">
                    <li class="interview-item">
                      <div class="index">{{ `Q${tsRsltSelInsVO.tsResult1.length + idx + 1}.` }}</div>
                      <div class="interview-item1">
                        <span class="qus">{{ item.questCntnt }}</span>
                        <span class="ans">{{ item.obtAnswCntnt }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="interview-result-area">
                  <div class="interview-ttl">평가자 의견</div>
                  <ul class="interview-list">
                    <li class="interview-item">
                      <div class="interview-item1">
                        <span class="ans">{{ tsRsltSelInsVO.evalrOpin }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </ur-box-container>
            </template>
          </mo-collapsing-header-view>
        </div>
        <!-- 상단고정 Tab UI //-->
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Screen from '~systems/mixin/screen'
import MSPRC321P from '@/ui/rc/MSPRC321P.vue'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPRC323P',
  screenId: 'MSPRC323P',
  mixins:[Screen],
  components: {
    MSPRC321P
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    itemInfo: {
      type: Object
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_Init()
  },
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      tsRsltSelInsVO: {}, // response
      totLength: 0,
      tsResult: true // 합격여부
    };
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 진입시, 최초실행 함수
     ******************************************************************************/
    fn_Init() {
       this.fn_SrchTsRslt()
    },
    /******************************************************************************
    * Function명 : fn_SrchTsRslt
    * 설명       : TS결과입력 조회
    *              As-Is(tss-ui-pjt) 소스 유지
    ******************************************************************************/
    fn_SrchTsRslt() {
      const lv_Vm = this
      let trnstId = 'txTSSRC60P1'

      let pPrams = {
        itmScCd: '1', // 항목구분(1:결과조회, 2:항목조회, 3:입력)
        parentId: this.itemInfo.parentId,  // 후보자ID
        hqOrgCd:  this.itemInfo.hqOrgCd,   // 본부조직코드
        dofOrgCd: this.itemInfo.dofOrgCd,  // 지역단조직코드
        fofOrgCd: this.itemInfo.fofCd      // 지점조직코드
      }

      lv_Vm.post(lv_Vm, pPrams, trnstId, 'S').then(function (response) {
        // console.log('TS조회', response)

        if (!_.isEmpty(response.body) && !_.isEmpty(response.body.tsItmList)) {
          lv_Vm.tsRsltSelInsVO = response.body
          let tsItmList = response.body.tsItmList

          lv_Vm.tsRsltSelInsVO.tsResult1 = []
          lv_Vm.tsRsltSelInsVO.tsResult2 = [] // 체크사항
          lv_Vm.tsRsltSelInsVO.totNum = 0

          tsItmList.forEach((tsItm) => {
            if (tsItm.clsfCd === '0001') { // 체크사항
              lv_Vm.tsRsltSelInsVO.tsResult2.push(tsItm)
            } else if (tsItm.clsfCd === '0012') { // 최종평가
              if (tsItm.obtAnswCntnt === '불합격') {
                lv_Vm.tsRsltSelInsVO.tsResult = false
                lv_Vm.tsRsltSelInsVO.tsLstEval = ''
              } else {
                lv_Vm.tsRsltSelInsVO.tsResult = true
                lv_Vm.tsRsltSelInsVO.tsLstEval = tsItm.obtAnswCntnt
              }
            } else { // 그 외
              lv_Vm.tsRsltSelInsVO.totNum += Number(tsItm.obtAnsw)
              lv_Vm.tsRsltSelInsVO.tsResult1.push(tsItm)
              // console.log('obtAnsw ===> ', Number(tsItm.obtAnsw))
            }
          })

          lv_Vm.totLength = lv_Vm.tsRsltSelInsVO.tsResult1.length + lv_Vm.tsRsltSelInsVO.tsResult2.length
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_Open321P
    * 설명       : TS 입력 팝업 호출
    *              TS 추가 버튼 클릭시, 실행
    *              TS 추가 입력 모두 완료시, 해당 TS 결과 팝업 동시 Close
    ******************************************************************************/
    fn_Open321P() {
      const lv_Vm = this

      // 팝업 open 셋팅
      this.popup321 = this.$bottomModal.open(MSPRC321P,{
        properties: {
          itemInfo: this.itemInfo
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          onPopupSearch: (pData) => {
            this.$bottomModal.close(lv_Vm.popup321)
            this.$emit('onPopupSearch', pData)
          }
        }
      })
    }
  }
};
</script>