/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC300M
 * 화면 설명: 배양현황
 * 화면 접근권한: 지점스텝, 지점장, 지역단스텝, 리크루팅본사스텝 (AD03, F110, F102, R100)
 * 작 성 일: 2022.06.09
 * 작 성 자: sh.park2022
 */
<template>
  <ur-page-container title="배양현황" :show-title="true" type="subpage" action-type="search"  @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenPop">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container v-if="!isNoData" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list">
        <mo-list :list-data="whlCndtrList">
          <template #list-item= "{item}">
            <div class="ns-list-button">
              <mo-list-item expandable ref="expItem">
                <div class="list-item__contents">
                  <div class="list-item__contents__title">
                    <span class="name txtSkip">{{ item.cndtrNm }}</span><!-- 후보자명 -->
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span>{{ item.fofNm }}</span><em>|</em><!-- 지점명 -->
                      <span>{{ item.qlfYn }}</span><!-- 적격여부 -->
                    </div>
                  </div>
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span>{{ item.recmFcNm }}({{ item.recmFcEno }})</span><!-- 추천FC명(사번) -->
                    </div>
                  </div>
                </div>
                <template v-slot:expand class="ns-gray-box">
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <span>{{ $bizUtil.dateDashFormat(item.dob) }}</span><em>|</em><!-- 생년월일 -->
                      <span>{{ item.sxcdNm === '남자'? '남' : '여' }}</span><!-- 성별 -->
                    </div>
                    <div class="list-item__contents__info">
                      <span class="info">세미나일자</span>
                      <span class="info">{{ item.semYmd === ' ' ? '-' : item.semYmd }}</span>
                    </div>
                    <div class="list-item__contents__info">
                      <span class="info">후보자등록일</span>
                      <span class="info">{{ $bizUtil.dateDashFormat(item.cndtrRegYmd) }}</span>
                    </div>
                  </div>
                </template>
              </mo-list-item>
              <div class="list-item__button">
                <mo-segment-wrapper v-model="item.groupSelectedItems" point>
                  <!-- 인터뷰 입력 :인터뷰날짜 존재시 -->
                  <mo-segment-button v-if="item.resentIntviDate !== ''" v-is-enable="{acl: ['S']}" @click="fn_OpenIntviPop(item, 'intviR')" class="mo-segment-button--checked">
                    {{ item.intviRsltYmd.trim() !== ''?$bizUtil.dateDashFormat(item.intviRsltYmd): $bizUtil.dateDashFormat(item.intviYmd)}}<!-- 인터뷰 날짜 -->
                  </mo-segment-button>
                  <mo-segment-button v-else @click="fn_OpenIntviPop(item, 'intviI')" v-is-enable="{acl: ['I']}" class="mo-segment-button--checked">
                    인터뷰 입력
                  </mo-segment-button>

                  <!-- TS 신청 :TS날짜 존재시 :disabled = 권한없을시 -->
                  <mo-segment-button
                    v-if="item.tsShow === 'request'" class="mo-segment-button--checked"
                    v-is-enable="{acl: ['I']} || item.intviRsltYmd.trim() === ''" 
                    @click="fn_OpenTSPop(item,'tsI')">
                    TS 신청
                  </mo-segment-button>

                  <mo-segment-button
                    v-else-if="item.tsShow === 'reqCancel'" class="mo-segment-button--checked"
                    v-is-enable="{acl: ['I']}"
                    @click="fn_OpenConfirm(item,'tsC')">
                    TS 신청 취소
                  </mo-segment-button>
                  <mo-segment-button v-else-if="item.resentTsDate === '-'" class="mo-segment-button--checked"
                    disabled> TS 신청 </mo-segment-button>

                  <mo-segment-button v-else class="mo-segment-button--checked"
                    v-is-enable="{acl: ['S']}"
                    @click="fn_OpenTSPop(item,'tsS')">
                    {{ item.resentTsDate }}
                  </mo-segment-button>
                  
                  <!-- 입과확보 -->
                  <!-- 누르면 바로 입과확보 신청/취소 변경 서비스 추가 (지점장, 지역단스텝, 리크루팅스텝 모두 가능하여, 권한체크 삭제) -->
                  <mo-segment-button @click="fn_OpenConfirm(item, 'encrs')" class="mo-segment-button--checked">
                    {{ item.encrsQurntYn !== 'Y' ? '입과확보' : '입과확보 취소' }}
                  </mo-segment-button>
                </mo-segment-wrapper>
              </div>
            </div>
          </template>
        </mo-list>
      </ur-box-container>
      <!-- NoData 영역 ::start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 존재하지 않습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역 ::end  -->
    </ur-box-container>

    <!-- 입과확보 입력/취소 Modal ::start -->
    <div class="ns-bottom-sheet ns-bottom-popup-m">
      <mo-bottom-sheet ref="refQurntYnModal">
          <template v-slot:title></template>
          <p class="ns-seminar-txt">{{ modalMsg }}</p>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ModalClose('N')">아니오</mo-button>
                <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ModalClose('Y')">예</mo-button>
              </div>
            </ur-box-container>

          </template>
      </mo-bottom-sheet>
    </div>
    <!-- 입과확보 입력/취소 Modal ::end -->

    <!-- toast Message: TS신청완료 -->
    <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>
  </ur-page-container>
</template>

<script>
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import Screen from '~systems/mixin/screen'

import MSPRC301P from '@/ui/rc/MSPRC301P.vue' // 상세조회
import MSPRC310P from '@/ui/rc/MSPRC310P.vue' // 인터뷰입력 Popup
import MSPRC311P from '@/ui/rc/MSPRC311P.vue' // 인터뷰결과 Popup
import MSPRC321P from '@/ui/rc/MSPRC321P.vue' // TS신청 Popup
import MSPRC323P from '@/ui/rc/MSPRC323P.vue' // TS결과 Popup

export default {
  name: 'MSPRC300M',
  screenId: 'MSPRC300M',
  mixins:[Screen],
  components: {
    MSPRC301P,
    MSPRC310P,
    MSPRC311P,
    MSPRC321P,
    MSPRC323P
  },
  data() {
    return {
      title: '',
      today: '', // 오늘날짜

      whlCndtrList: [], // 배양현황 리스트

      isNoData: false,   // 데이터 없음
      pOrgData300M: {}, // 최근 조직 조회 데이터
      pSrchCndtrNm: '', // 최근 후보자명 조회 입력값

      isSearched: false, // 조회이력 저장 변수
      popup301: {},  // 상세조회(MSPRC301P) 팝업
      pInterviewData: {}, // 인터뷰 입력(MSPRC310P) 데이터
      QurntItem: '', // 버튼 클릭한 후보생 정보
      modalMsg: '',
      popupComp: '' // 팝업 변수
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  created() {
    const lv_Vm = this
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    // 바텀 메뉴 보임 처리
    lv_Vm.$BottomManager.fn_SetBottomVisible(true)

    this.fn_Init()
  },
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  beforeDestroy() {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 진입시, 최초실행 함수
     ******************************************************************************/
    fn_Init() {
      const lv_Vm = this
      let firstPData = {}

      this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보
      // console.log('userInfo: ', this.userInfo)
      firstPData = this.fn_SetFirstParam()
      this.fn_SlectCnsltResultRC(firstPData)

      // 오늘 날짜 조회
      this.$commonUtil.getServerTime().then(function (response) {
        lv_Vm.today = moment(response).format('YYYYMMDD')
      })
    },
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.isSearched) {
        // 조회를 한번이라도 한 경우 전체 검색

        // 초기화
        this.isSearched = false // 검색이력 초기화
        this.pSrchCndtrNm = ''
        this.fn_Init()
      } else {
        // 조회를 하지 않았으면 뒤로가기
        this.$router.go(-1)
      }
    },
    /******************************************************************************
     * Function명 : fn_SetFirstParam
     * 설명       : 화면 진입시, 초기 데이터 조회 파라미터 셋팅
     *              일반사용자, FC일경우, 본인 사번으로 셋팅후 조회
     ******************************************************************************/
    fn_SetFirstParam() {
      let cnslt = ''
      if (this.userInfo.isFc === 'Y') {
        cnslt = this.userInfo.cnsltNo
      }

      let firstPData = {
                    cnslt:  cnslt,
                    dofOrg: this.userInfo.onpstDofNo,
                    fofOrg: this.userInfo.onpstFofOrgNo,
                    hofOrg: this.userInfo.onpstHofOrgNo
                  }
      return firstPData
    },
    /******************************************************************************
     * Function명 : fn_SlectCnsltResultRC
     * 설명       : 파라미터 데이터 셋팅
     ******************************************************************************/
    fn_SlectCnsltResultRC (pData) {
      pData = {
                cndtrNm : '',           // 후보자명
                cnsltEno: pData.cnslt,  // 컨설턴트 사번
                dofOrgCd: pData.dofOrg, // 선택사업부
                hofOrgCd: pData.hofOrg,  // 선택지역단
                fofOrgCd: pData.fofOrg // 선택지점
              }
      this.fn_GetCtvtPnsta(pData)
    },
    /******************************************************************************
     * Function명 : fn_GetCtvtPnsta
     * 설명       : 배양현황 리스트 조회
     ******************************************************************************/
    fn_GetCtvtPnsta(OrgData) {
      const lv_Vm = this

      let cntrNm = ''

      if (this.$bizUtil.isEmpty(this.pSrchCndtrNm)) {
        cntrNm = OrgData.cndtrNm
      } else {
        cntrNm = this.pSrchCndtrNm
      }

      let pParams = {
        cndtrNm   : cntrNm,  // 후보자명
        cnsltEno  : OrgData.cnsltEno, // 컨설턴트 사번
        hqOrgCd   : OrgData.hofOrgCd,  // 선택사업부
        dofOrgCd  : OrgData.dofOrgCd, // 선택지역단
        fofOrgCd  : OrgData.fofOrgCd // 선택지점
      }
      let trnstId = 'txTSSRC40S1'
      let auth = 'S'

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.whlCndtrList)) {
            // console.log(response.body)
            let result = response.body.whlCndtrList

            lv_Vm.fn_SetWhlCndtrList(result) // 배양현황 리스트 데이터 가공
            lv_Vm.isNoData = false
          } else {
            lv_Vm.isNoData = true
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isNoData = true
        })
    },
    /******************************************************************************
     * Function명 : fn_SetWhlCndtrList
     * 설명       : 인터뷰/TS 일자 셋팅
     *              As-Is(tss-ui-pjt) 소스 유지
     ******************************************************************************/
    fn_SetWhlCndtrList(result) {
      this.whlCndtrList = [] // 초기화
      let rtnValu = []

      for (let i = 0; i < result.length; i++) {
        let tempObj = this.$bizUtil.cloneDeep(result[i])

        // 인터뷰/TS 날짜의 경우 기존 EP 에서 실시한 데이터와 신규 화면에서 실시한 DATA 두 개 있음 (인터뷰 결과 팝업 띄울 수 있는건 신규로 실시한 것만)
        tempObj.resentIntviDate = ''
        tempObj.preIntviData = false

        //intviYmd: 인터뷰일자, intviRsltYmd: 인터뷰결과일자
        if (!this.$bizUtil.isEmpty(result[i].intviYmd) && !this.$bizUtil.isEmpty(result[i].intviRsltYmd)) { // 기존EP DATA와 신규 인터뷰 DATA 둘 다 존재 하면 최신 날짜
          if (!moment(result[i].intviYmd).isSame(result[i].intviRsltYmd) && moment(result[i].intviRsltYmd).isBefore(result[i].intviYmd)) {
            tempObj.resentIntviDate = moment(result[i].intviYmd).format('YYYY-MM-DD') // EP
            tempObj.preIntviData = true
          } else {
            tempObj.resentIntviDate = moment(result[i].intviRsltYmd).format('YYYY-MM-DD') // 모바일
          }
        } else if (!this.$bizUtil.isEmpty(result[i].intviYmd) && this.$bizUtil.isEmpty(result[i].intviRsltYmd)) { // 기존 EP DATA만 존재
          tempObj.resentIntviDate = moment(result[i].intviYmd).format('YYYY-MM-DD')
          tempObj.preIntviData = true
        } else if (this.$bizUtil.isEmpty(result[i].intviYmd) && !this.$bizUtil.isEmpty(result[i].intviRsltYmd)) { // 신규 DATA만 존재
          tempObj.resentIntviDate = moment(result[i].intviRsltYmd).format('YYYY-MM-DD')
        }
        // TS 날짜
        // 인터뷰 X - 진행불가
        // 인터뷰 O - TS 신청일 O - TS 결과 EP / 모바일 (TS 결과입력은 당일에만 가능)
        //                                 O     O - TS신청일/결과일/오늘 비교해서 -> 신청취소/신청버튼 아니면 결과 날짜
        //                                 X     O - TS신청일/결과일/오늘 비교해서 -> 신청취소/신청버튼 아니면 결과 날짜
        //                                 O     X - TS신청일/결과일/오늘 비교해서 -> 신청취소/신청버튼 아니면 결과 날짜
        //                                 X     X - TS신청일/오늘날짜 비교해서 신청/신청취소
        //          - TS 신청일 X - TS 결과 EP / 모바일
        //                                 O     O - 최신 날짜
        //                                 X     O - 날짜
        //                                 O     X - 날짜
        //                                 X     X - 신청 버튼
        tempObj.resentTsDate = ''
        tempObj.tsShow = ''
        tempObj.preTsData = false
        if (tempObj.resentIntviDate !== '') { // 인터뷰 날짜 없으면 TS신청 못 함
          if (result[i].tsAplYmd.trim() !== '') { // TS신청일자 O
            if (result[i].tsEfYmd.trim() === '' && result[i].tsRsltYmd.trim() === '') { // EP, 모바일 결과 데이터 둘 다 X
              tempObj.tsShow = (moment(result[i].tsAplYmd).isBefore(this.today)) ? 'request' : 'reqCancel' // 오늘 보다 이전에 신청했으면 '신청'버튼 미래에 했으면 '신청취소'
            } else {
              if (result[i].tsEfYmd.trim() !== '' && result[i].tsRsltYmd.trim() !== '') { // EP, 모바일 결과 데이터 둘 다 O -> 최신 결과 날짜
                // tempObj.resentTsDate = (moment(result[i].tsEfYmd).isBefore(result[i].tsRsltYmd)) ? moment(result[i].tsRsltYmd).format('YYYY-MM-DD') : moment(result[i].tsEfYmd).format('YYYY-MM-DD')
                if (!moment(result[i].tsEfYmd).isSame(result[i].tsRsltYmd) && moment(result[i].tsRsltYmd).isBefore(result[i].tsEfYmd)) {
                  tempObj.resentTsDate = moment(result[i].tsEfYmd).format('YYYY-MM-DD') // EP
                  tempObj.preTsData = true
                } else {
                  tempObj.resentTsDate = moment(result[i].tsRsltYmd).format('YYYY-MM-DD') // 모바일
                }
              } else if (result[i].tsEfYmd.trim() === '' && result[i].tsRsltYmd.trim() !== '') { // 모바일 DATA만 존재
                tempObj.resentTsDate = moment(result[i].tsRsltYmd).format('YYYY-MM-DD')
              } else if (result[i].tsEfYmd.trim() !== '' && result[i].tsRsltYmd.trim() === '') { // EP DATA만 존재
                tempObj.resentTsDate = moment(result[i].tsEfYmd).format('YYYY-MM-DD')
                tempObj.preTsData = true
              }
              if (moment(tempObj.resentTsDate).isSame(result[i].tsAplYmd) || moment(result[i].tsAplYmd).isBefore(tempObj.resentTsDate)) { // 신청일 = 결과입력일 : 결과확인 날짜
                tempObj.tsShow = ''
              } else {
                if (moment(tempObj.resentTsDate).isBefore(result[i].tsAplYmd) && moment(result[i].tsAplYmd).isBefore(this.today)) { // 결과입력 - 신청일 - 오늘 : 신청하고 결과입력 안했으므로 '신청'버튼
                  tempObj.tsShow = 'request'
                } else { // 신청일이 미래 이므로 '신청취소' 버튼
                  tempObj.tsShow = 'reqCancel'
                }
              }
            }
          } else { // TS신청일자 X
            if (result[i].tsEfYmd.trim() === '' && result[i].tsRsltYmd.trim() === '') { // 기존EP DATA와 신규 TS 결과 DATA 둘 다 X
              tempObj.tsShow = 'request' // 신청 버튼
            } else if (result[i].tsEfYmd.trim() !== '' && result[i].tsRsltYmd.trim() === '') { // 기존 EP DATA만 존재
              tempObj.resentTsDate = moment(result[i].tsEfYmd).format('YYYY-MM-DD')
              tempObj.preTsData = true
            } else if (result[i].tsEfYmd.trim() === '' && result[i].tsRsltYmd.trim() !== '') { // 신규 DATA만 존재
              tempObj.resentTsDate = moment(result[i].tsRsltYmd).format('YYYY-MM-DD')
            } else { // 기존EP DATA와 신규 TS 결과 DATA 둘 다 있으면 최신 날짜
              if (!moment(result[i].tsEfYmd).isSame(result[i].tsRsltYmd) && moment(result[i].tsRsltYmd).isBefore(result[i].tsEfYmd)) {
                tempObj.resentTsDate = moment(result[i].tsEfYmd).format('YYYY-MM-DD') // EP
                tempObj.preTsData = true
              } else {
                tempObj.resentTsDate = moment(result[i].tsRsltYmd).format('YYYY-MM-DD') // 모바일
              }
            }
          }
        } else {
          tempObj.resentTsDate = '-'
        }

        rtnValu.push(tempObj)
      }
      // console.log('serviceData', rtnValu)
      this.whlCndtrList = rtnValu
      return rtnValu
    },
    /*****************************************************************************************
     * 함수 : fn_SaveIntviData
     * 설명 : 인터뷰 데이터 저장
     *****************************************************************************************/
    fn_SaveIntviData (pData) {
      const lv_Vm = this
      let lv_intviItmList = []

      if (_.isEmpty(pData)) return

      // 인터뷰 입력 - 입력값 취합
      for (let i = 0; i < pData.intviData.length - 1; i++) {
        let tempObj = {
          prgSeq : pData.intviData[i].prgSeq,          // 진행순서
          clsfCd : pData.intviData[i].clsfCd,          // 분류코드
          clsfItm : pData.intviData[i].clsfItm,        // 분류항목
          obtCd : pData.intviData[i].obtCd,            // 질문코드
          obtAnsw : pData.intviData[i].obtAnsw,        // 질문내용
          obtAnswCntnt : pData.intviData[i].intviValu, // 객관식답변 코드
          questCntnt : pData.intviData[i].answCntnt    // 객관식답변내용
        }

        lv_intviItmList.push(tempObj)
      }

      let pParams = {
        cnsltEno: this.userInfo.userId, // 컨설턴트사번
        parentId: pData.parentId,       // 후보자ID
        intviYmd: this.today,           // 인터뷰 일자
        itmScCd: '3',                   // 항목구분(1:결과 조회 2: 질문지 조회, 3: 결과 입력)
        bmngrOpin: pData.intviData[pData.intviData.length - 1].answCntnt, // 지점장의견
        intviItmList: lv_intviItmList   // 인터뷰 입력값 리스트
      }

      let trnstId = 'txTSSRC40S2'
      let auth = 'S'

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body) && response.body.msgTyp === 'S') {
            // console.log(response.body)
            lv_Vm.fn_SetIntviRsltYmd(response.body.parentId)
            lv_Vm.fn_Init()
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /*****************************************************************************************
     * 함수 : fn_SetIntviRsltYmd
     * 설명 : 인터뷰 저장시, 인터뷰 입력한 날짜 = 오늘 날짜 셋팅
     *****************************************************************************************/
    fn_SetIntviRsltYmd(parentId) {
      const lv_Vm = this

      this.whlCndtrList.forEach(item => {
        if (item.parentId === parentId) {
          item.intviRsltYmd = lv_Vm.today
        }
      })
    },
    /******************************************************************************
     * Function명 : fn_OpenPop
     * 설명       : 상세조회 팝업 호출
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
    fn_OpenPop() {
      const lv_Vm = this
      this.popup301 = this.$bottomModal.open(MSPRC301P,{
        properties: {
          title: '상세조회', // 팝업의 타이틀 셋팅
          pOrgData300M: this.pOrgData300M // 최근 조직 조회 데이터
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          onPopupSearch: (pData) => {
            this.$bottomModal.close(lv_Vm.popup301)

            // 조회이력 저장
            lv_Vm.isSearched = true
            
            // 마지막 조직 조회 데이터 셋팅
            // 마지막 조회 정렬로 셋팅(default: 최신순)
            lv_Vm.pOrgData300M = pData.OrgData.pOrgData
            lv_Vm.pSrchCndtrNm = pData.OrgData.cndtrNm
            lv_Vm.fn_GetCtvtPnsta(pData.OrgData)
          }
        }
      })
    },
    /******************************************************************************
     * Function명 : fn_OpenIntviPop
     * 설명       : 인터뷰(입력/결과) 팝업 호출
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     *             입력과 결과 동일한 listener 함수 등록
     *             => 결과 팝업에서 인터뷰 추가할 경우 고려
     ******************************************************************************/
    fn_OpenIntviPop(item, openType) {
      const lv_Vm = this
      let openPopComp = ''

      let listener = {
        // 팝업 닫히면서 실행되는 함수
        onPopupSearch: (pData) => { // 인터뷰 결과 - 인터뷰 추가시, 입력 팝업과 동일하게 실행
          lv_Vm.pInterviewData = pData
          this.$bottomModal.close(lv_Vm.popupComp)
          if (!_.isEmpty(pData)) {
            lv_Vm.fn_SaveIntviData(pData)
          }
        }
      }

      if (openType === 'intviR') { // 인터뷰 결과 조회
        openPopComp = MSPRC311P
      } else if (openType === 'intviI') { // 인터뷰 입력
        openPopComp = MSPRC310P
      }
      
      // 팝업 open 셋팅
      this.popupComp = this.$bottomModal.open(openPopComp,{
        properties: {
          itemInfo: item
        },
        listeners: listener
      })
    },
    /******************************************************************************
     * Function명 : fn_OpenTSPop
     * 설명       : TS신청 팝업 호출
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
    fn_OpenTSPop(item, pType) {
      const lv_Vm = this
      let openPopComp = ''

      let listener = {
        onPopupSearch: (pData) => {
          this.$bottomModal.close(lv_Vm.popupComp)
          if (!_.isEmpty(pData.itemInfo)) {
            lv_Vm.whlCndtrList.forEach(item => {
              if (item.parentId === pData.itemInfo.parentId) {
                item.tsShow = 'reqCancel'
                item.tsAplYmd = pData.dateObj.value
              }
            })
          }
          this.$refs.snackbar.show({ text: '신청이 완료되었습니다.' })
          // 변경시, 배양현황 후보자 목록 재조회
          lv_Vm.fn_Init()
        }
      }

      let title = ''
      if (pType === 'tsI') {
        openPopComp = MSPRC321P
      } else if (pType === 'tsS') {
        openPopComp = MSPRC323P
        title = 'TS 결과'
      }

      // 팝업 open 셋팅
      this.popupComp = this.$bottomModal.open(openPopComp,{
        properties: {
          itemInfo: item,
          title: title
        },
        listeners: listener
      })
    },
    /******************************************************************************
     * Function명 : fn_OpenConfirm
     * 설명       : 입과확보 팝업 호출
     ******************************************************************************/
    fn_OpenConfirm(item, param) {
      this.QurntItem = item
      this.modalParam = param

      if (param === 'encrs') {
        if (item.encrsQurntYn !== 'Y') {
          this.modalMsg = '입과확보 입력 하시겠습니까?'
        } else {
          this.modalMsg = '입과확보 취소 하시겠습니까?'
        }
      } else if (param === 'tsC') {
        // console.log('item>>', item)
        this.modalMsg = moment(item.tsAplYmd).format('YYYY-MM-DD') +' 신청한 ['+item.cndtrNm+'] 후보자의 TS신청을 취소하시겠습니까?'
      }
      this.$refs.refQurntYnModal.open()
    },
    /******************************************************************************
     * Function명 : fn_ModalClose
     * 설명       : 모달 -- TS신청취소 / 입과확보(예, 아니오)
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
    fn_ModalClose(clickYn) {
      if (this.modalParam === 'tsC') {
        if (clickYn === 'Y') { // 예
          this.fn_OnPositive()
        }
      } else if (this.modalParam === 'encrs') {
        if (clickYn === 'Y') { // 예
          if (this.QurntItem.encrsQurntYn === 'N') {
            this.QurntItem.encrsQurntYn = 'Y'
          } else {
            this.QurntItem.encrsQurntYn = 'N'
          }
          this.fn_OnPositive()
        }
      }
      this.$refs.refQurntYnModal.close()
    },
    /******************************************************************************
    * Function명 : fn_OnPositive
    * 설명       : 모달 -- '예' 클릭시 -- (TS 신청 취소) / (입과확보 입력/취소)
   ******************************************************************************/
    fn_OnPositive() {
      const lv_Vm = this
      let trnstId = ''
      let cndtrInfo = this.QurntItem

      let pPrams = {
        hqOrgCd:  cndtrInfo.hqOrgCd,  // 본부조직코드
        dofOrgCd: cndtrInfo.dofOrgCd, // 지역단조직코드
        fofOrgCd: cndtrInfo.fofCd,    // 지점조직코드
        parentId: cndtrInfo.parentId  // 후보자ID
      }

      if (this.modalParam === 'tsC') { // TS 신청취소
        trnstId = 'txTSSRC60I1'
        pPrams.stafEno = this.userInfo.cnsltNo
        pPrams.tsAplYmd = cndtrInfo.tsAplYmd // TS신청일자
        pPrams.tsAplSc = '2' // TS신청구분(1:신청, 2:취소)
      } else if (this.modalParam === 'encrs') {
        trnstId = 'txTSSRC40S3' // 입과확보 입력/취소
        pPrams.cnsltEno = this.userInfo.userId
        pPrams.encrsScCd = (this.QurntItem.encrsQurntYn === 'Y') ? '1' : '2' // 1:신청, 2:취소
      }

      lv_Vm.post(lv_Vm, pPrams, trnstId, 'S').then(function (response) {
        if (response.body !== null && response.body !== '') {
          // console.log(response.body)
          let result = response.body
          if (!_.isEmpty(result) && result.msgTyp === 'S') {

            if (trnstId === 'txTSSRC60I1') {
              lv_Vm.QurntItem.tsAplYmd = ''
              lv_Vm.fn_SetWhlCndtrList(lv_Vm.whlCndtrList)
            }

            if (trnstId === 'txTSSRC40S3') {
              if (pPrams.encrsScCd === '1') {
                lv_Vm.QurntItem.encrsQurntYn = 'Y'
              } else {
                lv_Vm.QurntItem.encrsQurntYn = 'N'
              }
            }
            // 변경시, 배양현황 후보자 목록 재조회
            // lv_Vm.fn_Init()
          }
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    }
  }
}
</script>
