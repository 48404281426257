/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC322P
 * 화면 설명: TS신청 - 시간별 현황 팝업
 * 작 성 일: 2022.06.21
 * 작 성 자: sh.park2022
 */
<template>
  <ur-page-container :show-title="true" title="TS신청" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area">

        <!-- 상단고정 Tab UI -->
        <div class="ns-sliding-topbar">
          <mo-collapsing-header-view headerColor="#fff">
            <template #fixed="{}">
              <div class="ns-ts-info">
                <p class="ts-date">{{ dateTitle }}</p>
                <div class="ts-info-box">
                  <span>후보자명<span class="txtBlack ml10">{{ custNm }}</span></span>
                  <em>|</em>
                  <span>신청일자<span class="txtBlack ml10">{{ dateStr }}</span></span>
                </div>
                <div class="ts-tb-th">
                  <span>시간</span>
                  <span>신청명수</span>
                </div>
              </div>
              
            </template>

            <!-- collapsing 영역 -->
            <template #collapsing>
            </template>

            <!-- stickey 영역 -->
            <template #scroll>
              <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-contract-list">
                <mo-list :list-data="tsPnstaDtlList">
                  <template #list-item="{item}">  
                    <mo-list-item :class="fn_CheckOverTime(item.time) ? 'disable' : ''">
                      <div class="list-item__contents">
                        <div class="list-item__contents__info">
                          <div class="info_box">
                            <div class="ns-radio-list">
                              <mo-radio v-model="chkRdo" :value="item.time" class="ns-radio" :disabled="fn_CheckOverTime(item.time)">{{ item.time }}</mo-radio>
                            </div>  
                          </div>
                          <div class="txt_right w40p-center">
                            <span>{{ item.tsAplNprsn }}</span>
                          </div>
                        </div>
                      </div>
                    </mo-list-item>
                  </template>
                </mo-list>
              </ur-box-container>
            </template>
          </mo-collapsing-header-view>
          <br/><br/><br/><br/><br/><br/><br/>
        </div>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Close">취소</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_TsAplyCncr" :disabled="!chkRdo">신청</mo-button>
          </div>
        </ur-box-container>
        <!-- 상단고정 Tab UI //-->
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPRC322P',
  screenId: 'MSPRC322P',
  mixins:[Screen],
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    pData: { // custNm: 후보자명, dateObj: 신청 날짜
      type: Object
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // console.log('Param pData', this.pData)
    this.fn_Init()
  },
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_userInfo: {}, // 사용자 정보
      lv_custInfo:{},  // 후보자 정보
      
      custNm: '', // 후보자 이름
      dateStr: '', // 신청 날짜
      dateTitle: '', // 00월 00일
      chkRdo: false, // 시간 선택값

      tsPnstaDtlList: [],
      // 시간 / TS신청 인원 form
      tsAplyTimeList: [
        {time: '09:00~09:30', tsAplNprsn: '0'},
        {time: '09:30~10:00', tsAplNprsn: '0'},
        {time: '10:00~10:30', tsAplNprsn: '0'},
        {time: '10:30~11:00', tsAplNprsn: '0'},
        {time: '11:00~11:30', tsAplNprsn: '0'},
        {time: '11:30~12:00', tsAplNprsn: '0'},
        {time: '13:00~13:30', tsAplNprsn: '0'},
        {time: '13:30~14:00', tsAplNprsn: '0'},
        {time: '14:00~14:30', tsAplNprsn: '0'},
        {time: '14:30~15:00', tsAplNprsn: '0'},
        {time: '15:00~15:30', tsAplNprsn: '0'},
        {time: '15:30~16:00', tsAplNprsn: '0'},
        {time: '16:00~16:30', tsAplNprsn: '0'},
        {time: '16:30~17:00', tsAplNprsn: '0'},
        {time: '17:00~17:30', tsAplNprsn: '0'},
        {time: '17:30~18:00', tsAplNprsn: '0'},
        {time: '18:00~18:30', tsAplNprsn: '0'},
        {time: '18:30~19:00', tsAplNprsn: '0'}
      ]
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 진입시, 최초실행 함수
     ******************************************************************************/
    fn_Init() {
      // 초기 전역변수 셋팅
      this.lv_userInfo = this.getStore('userInfo').getters.getUserInfo
      this.lv_custInfo = this.pData.custInfo
      this.custNm    = this.pData.custInfo.cndtrNm
      this.dateTitle = this.pData.dateObj.title
      this.dateStr   = this.pData.dateObj.text

      this.fn_SelListTsPnstaDtlInqr()
    },
    /******************************************************************************
    * Function명 : fn_SelListTsPnstaDtlInqr
    * 설명       : TS 신청 현황 상세조회한다.
    ******************************************************************************/
    fn_SelListTsPnstaDtlInqr() {
      const lv_Vm = this
      let trnstId = 'txTSSRC60S2'
      let pPrams = {
        hqOrgCd:  this.lv_custInfo.hqOrgCd,    // 본부조직코드
        dofOrgCd: this.lv_custInfo.dofOrgCd,   // 지역단조직코드
        fofOrgCd: this.lv_custInfo.fofCd,      // 지점조직코드
        tsAplYmd: this.pData.dateObj.value, // TS신청일자
        tsInqrCd: '1'  //TS조회코드 (1:신청, 2:취소)
      }
      lv_Vm.post(lv_Vm, pPrams, trnstId, 'S').then(function (response) {
        // console.log(response)

        if (!_.isEmpty(response.body) && !_.isEmpty(response.body.tsPnstaList)) {
          let result = response.body.tsPnstaList
          
          lv_Vm.tsPnstaDtlList = []
          let tmpArr = JSON.parse(JSON.stringify(lv_Vm.tsAplyTimeList))
          tmpArr.forEach((item) => {
            let currTime = item.time.substr(0, 5)
            let aplyCnt = result.filter(item2 => currTime === `${item2.tsAplTm}:${item2.tsAplMm}`).length
            item.tsAplNprsn = isNaN(aplyCnt) ? 0 : aplyCnt
          })
          lv_Vm.tsPnstaDtlList = [ ...tmpArr ]
        } else {
          lv_Vm.tsPnstaDtlList = JSON.parse(JSON.stringify(lv_Vm.tsAplyTimeList))
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
    * Function명 : fn_TsAplyCncr
    * 설명       : TS 신청 함수
    ******************************************************************************/
    fn_TsAplyCncr() {
      const lv_Vm = this
      
      let trnstId = 'txTSSRC60I1'
      let pPrams = {
        stafEno:  this.lv_userInfo.cnsltNo,    // 임직원사번
        hqOrgCd:  this.lv_custInfo.hqOrgCd,    // 본부조직코드
        dofOrgCd: this.lv_custInfo.dofOrgCd,   // 지역단조직코드
        fofOrgCd: this.lv_custInfo.fofCd,      // 지점조직코드
        parentId: this.lv_custInfo.parentId,   // 후보자ID
        tsAplYmd: this.pData.dateObj.value, // TS신청일자
        tsAplTm: this.chkRdo.substr(0,2), // TS신청시간(1~24)
        tsAplMm: this.chkRdo.substr(3,2), // TS신청분(30분단위)
        tsAplSc: '1' // TS신청구분(1: 신청, 2:취소)
      }
      // console.log(trnstId, ' ===> ', pPrams)
      lv_Vm.post(lv_Vm, pPrams, trnstId, 'S').then(function (response) {
        if (!_.isEmpty(response.body) && response.body.msgTyp === 'S') {

          // lv_Vm.$emit('onPopupSearch', lv_Vm.chkRdo)
          lv_Vm.fn_SendPush(lv_Vm.chkRdo, pPrams.tsAplYmd, pPrams.tsAplTm, pPrams.tsAplMm)
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /**
     * PUSH 발송
     */
    fn_SendPush(chkRdo, tsAplYmd, tsAplTm, tsAplMm) {
      const lv_Vm = this
      let pPrams = {
        tsAplYmd: lv_Vm.$bizUtil.dateYyMmDdFormat(tsAplYmd),
        tsAplTm: tsAplTm,
        tsAplMm: tsAplMm,
        userDeptNm: lv_Vm.getStore('userInfo').getters.getUserInfo.userDeptNm
      }
      let trnstId = 'txTSSRC40P1'

      lv_Vm.post(lv_Vm, pPrams, trnstId, 'S').then(function (response) {
        lv_Vm.$emit('onPopupSearch', chkRdo)
      }).catch(function (error) {
        window.vue.error(error)
      })      
    },
    /******************************************************************************
     * Function명 : fn_CheckOverTime
     * 설명       : 현재 시각과 비교하는 함수
     *               TS신청 >> 지난 시간은 체크 못함
     ******************************************************************************/
    fn_CheckOverTime(chkRdo) {
      let currDate = moment()

      let time1 = chkRdo.substr(0, 5)
      let time2 = chkRdo.substr(6, 5)
      if (this.pData.dateObj.value === currDate.format('YYYYMMDD')) {
        let selDate1 = `${this.pData.dateObj.text} ${time1}`
        let selDate2 = `${this.pData.dateObj.text} ${time2}`
        if (!moment(currDate).isBetween(selDate1, selDate2)) {
          if (moment.duration(currDate.diff(selDate1)).asHours() > 0 || moment.duration(currDate.diff(selDate2)).asHours() > 0) {
            // this.getStore('confirm').dispatch('ADD', 'TS신청 시간은 현재보다 미래시간을 선택해주시길 바랍니다.')
            return true
          }
          return false
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_Close
    * 설명       : 취소 선택하여, 팝업 닫기
    * return: 'cancle'
    ******************************************************************************/
    fn_Close() {
      // 팝업 입력값 emit
      this.$emit('onPopupSearch', 'cancle')
    }
  }
};
</script>