/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC311P
 * 화면 설명: 배양현황 - 인터뷰 결과조회
 * 작 성 일: 2022.06.09
 * 작 성 자: sh.park2022
 */
<template>
  <ur-page-container :show-title="false" title="인터뷰결과" type="popup">
    <ur-box-container direction="row" alignV="start">

      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-btn-single">
        <div class="relative-div">
          <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" v-is-enable="{acl: ['I']}" @click="fn_OpenPop310P">인터뷰추가</mo-button>
        </div>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-text-subtitle">
        <ur-text componentid="span_common_text_001" class="subttl">인터뷰결과 내역</ur-text>
      </ur-box-container>

      <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-dropdownlist-interview">        
          <msp-bottom-select :items="historyList" v-model="selecIntivDate" underline class="ns-dropdown-sheet" />
      </ur-box-container>

      <ur-box-container v-show="intviCatgData.length > 0" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-interview-result">
        <div class="interview-result-area" v-for="(catgItem) in intviCatgData" :key="catgItem.obtCd">
          <div class="interview-ttl">{{ catgItem.title }}</div><!-- 카테고리 타이틀 -->
          <ul class="interview-list"  v-for="subItem in catgItem" :key="subItem.prgSeq">
            <li class="interview-item">
              <div class="index">{{ `Q${subItem.dataSeq}.` }}</div><!-- 문항번호 -->

              <div class="interview-item1">
                <span class="qus">{{ subItem.obtAnsw }}</span><!-- 문항 타이틀 -->
                <span class="ans">{{ subItem.questCntnt }}</span><!-- 문항 답변내용 -->
              </div>
            </li>
          </ul>
        </div>
        <div class="interview-result-area">
          <div class="interview-ttl">지점장 의견</div>
          <ul class="interview-list">
            <li class="interview-item">
              <div class="index">{{ `Q${intviListData.length+1}.` }}</div><!-- 문항번호 -->

              <div class="interview-item1">
                <span class="qus">아래 답변 영역에 지점장 의견을 작성해주세요.</span>
                <span class="ans">{{ intviResult.bmngrOpin }}</span>
              </div>
            </li>
          </ul>
        </div>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'

import MSPRC310P from '@/ui/rc/MSPRC310P.vue' // 인터뷰입력 Popup

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPRC311P',
  screenId: 'MSPRC311P',
  mixins:[Screen],
  components: {
    MSPRC310P
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    itemInfo: {
      type: Object
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_Init()
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_UserInfo: {}, // 사용자 정보

      intviResult: {}, // response Data
      intviRsltYmd: '', // 인터뷰 결과 날짜
      intviListData: [], // 인터뷰 질문/답변 데이터
      intviCatgData: [], // // 카테고리별 문항 리스트
      historyList: [{text: '선택', value: '0'}],
      selecIntivDate: '0',
      isFirst: true,
      popup310: {}
    };
  },
  /************************************************************************************************
   * Life Cycle 함수 정의 영역
   ***********************************************************************************************/
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    selecIntivDate() {
      let selectDate = ''

      if (!this.isFirst) {
        selectDate = this.selecIntivDate
      } else {
        selectDate = this.itemInfo.intviRsltYmd
      }

      this.fn_SetIntviResult(selectDate) // 인터뷰 결과 조회
      this.isFirst = false
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 진입시, 최초실행 함수
     ******************************************************************************/
    fn_Init() {
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보
      // console.log('itemInfo', this.itemInfo)
      this.intviRsltYmd = moment(this.itemInfo.intviRsltYmd).format('YYYY-MM-DD')
      
      this.fn_SetIntviHistory()
    },
    /******************************************************************************
     * Function명 : fn_SetIntviResult
     * 설명       : 인터뷰 질문지/결과지 데이터 셋팅
    ******************************************************************************/
    fn_SetIntviResult(pDate) {
      const lv_Vm = this
      let selectDate = ''
      
      if (!_.isEmpty(pDate)) {
        selectDate = pDate
      } else {
        if (this.itemInfo.intviRsltYmd.trim() !== '') {
          selectDate = this.itemInfo.intviRsltYmd
        } else {
          selectDate = this.itemInfo.intviYmd
        }
      }

      this.intviCatgData = [] // 초기화

      let pParams = {
        cnsltEno: this.lv_UserInfo.userId, // 컨설턴트사번
        hqOrgCd: this.itemInfo.hqOrgCd, // 본부조직코드
        dofOrgCd: this.itemInfo.dofOrgCd, // 지역단조직코드
        fofOrgCd: this.itemInfo.fofCd, // 지점조직코드
        intviYmd: selectDate,           // 인터뷰 일자
        parentId: this.itemInfo.parentId, // 후보자ID
        itmScCd: '1' // 항목구분(1:결과 조회 2: 질문지 조회, 3: 결과 입력)
      }
      let trnstId = 'txTSSRC40S5'
      let auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.intviItmList)) {
            // console.log('response>>', response.body)
            let lv_intviItmList = response.body.intviItmList
            lv_Vm.intviResult = response.body
            lv_Vm.chnlScCd = response.body.chnlScCd // 채널구분코드(11:FC, 12:SFP 등)
            let fcIntviItmList = []
            // // lv_Vm.chnlScCd = '13' // test
            if (lv_Vm.chnlScCd === '12') { // SFP
              for (let i = 0; i < lv_intviItmList.length; i++) {
                if (lv_intviItmList[i].obtCd !== '0003' && lv_intviItmList[i].obtCd !== '0012') { // 0003:현재 막내 자녀의 학년 및 연령, 0012:육아부담
                  fcIntviItmList.push(lv_intviItmList[i])
                }
              }
            } else { // FC인 경우 '부모님동거', '군필' 질문 항목 안 보임
              for (let i = 0; i < lv_intviItmList.length; i++) {
                if (lv_intviItmList[i].obtCd !== '0020' && lv_intviItmList[i].obtCd !== '0017') { // 0020:부모님 동거, 0017:군필
                  fcIntviItmList.push(lv_intviItmList[i])
                }
              }
            }
            lv_Vm.intviListData = fcIntviItmList // 인터뷰 질문/답변 데이터
            lv_Vm.fn_ClsfCatg()
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
     * Function명 : fn_ClsfCatg
     * 설명       : 카테고리별 분류
     *              카테고리1: 답변내용(FC배양, 가족관계, 직업/경력, 출/퇴근여건)
     *              카테고리2: 체크리스트
     *              카테고리3: 배양정도
     * return: List = [카테고리1, 카테고리2, 카테고리3]
    ******************************************************************************/
    fn_ClsfCatg() {
      let rtnList = []
      
      let dataList1 = []
      let dataList2 = []
      let dataList3 = []

      dataList1.title = '답변내용'
      dataList2.title = '체크사항'
      dataList3.title = '배양정도'

      // prgSeq 기준 정렬(오름차순)
      this.intviListData = this.intviListData.sort(function(a,b) {
        return a.prgSeq - b.prgSeq
      })

      for (let i = 0; i < this.intviListData.length; i++) {
        if (_.isEmpty(this.intviListData[i])) return

        // 카테고리별 구분
        if (Number(this.intviListData[i].clsfCd) <= 4) { // FC배양, 가족관계, 직업/경력, 출/퇴근여건
          dataList1.push(this.intviListData[i])
        } else if (Number(this.intviListData[i].clsfCd) === 5) { // 체크리스트
          dataList2.push(this.intviListData[i])
        } else if (Number(this.intviListData[i].clsfCd) === 6) { // 배양정도
          dataList3.push(this.intviListData[i])
        }
      }

      // 화면에 보이는 문항 index 셋팅
      this.intviListData.forEach((item, index) =>{
        item.dataSeq = index+1
      })

      // 카테고리별 문항리스트 셋팅
      this.intviCatgData = [dataList1, dataList2, dataList3] // 이중 List (화면에 카테고리 분류선에 의해)
      return rtnList
    },
    /******************************************************************************
     * Function명 : fn_SetIntviHistory
     * 설명       : 인터뷰 입력 이력 조회
     *              > 조회후, 가장 최근 날짜로 인터뷰 결과 조회
     *              > 지점장 의견이 있는걸로 셋팅
     *              > DB에서 조회, DB에 없을경우 PO에서 조회
     *              > 인터뷰 입력시, DB에 저장 => DB에 처음 저장시, PO에서 받아온 데이터는 삭제됨
    ******************************************************************************/
    fn_SetIntviHistory() {
      const lv_Vm = this
      let pParams = {
        parentId: this.itemInfo.parentId,
      }

      let trnstId = 'txTSSRC40S4'
      let auth = 'S'

      this.historyList = [{text: '선택', value: '0'}] // 초기화
      this.selecIntivDate = '0'

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // console.log('response>', response)

          if (!_.isEmpty(response.body)) {
            let invList = response.body.invList
            let intviRsltYmd = lv_Vm.itemInfo.intviRsltYmd
            lv_Vm.historyList = []
  
            let itemRsltYmd = invList.filter(item=> item.intviYmd === lv_Vm.itemInfo.intviRsltYmd)
            if (invList.length > 0) {
              for (let i=0; i<invList.length; ++i) {
                lv_Vm.historyList.push(invList[i])
              }
            }
            if (itemRsltYmd.length < 1) {
              lv_Vm.historyList.push({intviYmd: intviRsltYmd, text:moment(intviRsltYmd).format('YYYY-MM-DD'), value: intviRsltYmd, bmngrOpin: 'pass'})
            }
  
            if (lv_Vm.historyList.length > 0) {
              for (let i=0; i<lv_Vm.historyList.length; ++i) {
                if (lv_Vm.selecIntivDate === '0' && lv_Vm.historyList[i].bmngrOpin.trim() !== '') {
                  lv_Vm.selecIntivDate = lv_Vm.historyList[i].intviYmd
                }
              }
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_OpenPop310P
    * 설명       : 인터뷰 입력 팝업 호출
    *              인터뷰 추가 버튼 클릭시, 실행
    *              인터뷰 추가 입력 모두 완료시, 해당 인터뷰 결과 팝업 동시 Close
    ******************************************************************************/
    fn_OpenPop310P() {
      const lv_Vm = this

      // 팝업 open 셋팅
      this.popup310 = this.$bottomModal.open(MSPRC310P,{
        properties: {
          itemInfo: this.itemInfo
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          onPopupSearch: (pData) => {
            this.$bottomModal.close(lv_Vm.popup310)
            this.$emit('onPopupSearch', pData)
          }
        }
      })
    }
  }
};
</script>