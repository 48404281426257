/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC310P
 * 화면 설명: 배양현황 - 결과입력
 * 작 성 일: 2022.06.09
 * 작 성 자: sh.park2022
 */
<template>
  <ur-page-container :show-title="false" title="인터뷰입력" type="popup" @before-close="fn_ConfmModalOpen">
    <ur-box-container direction="column" alignV="start">

      <!-- 간편정보 아코디언 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-interview-list">
        <mo-list :list-data="intviResult">
          <template #list-item= "{item}">
            <div class="ns-list-button">
              <mo-list-item expandable ref="expItem">
                <div class="list-item__contents">
                  <div class="list-item__contents__info">
                    <div class="txt_box">
                      <span class="theads">후보자</span>
                      <span class="txtSkip">{{ itemInfo.cndtrNm }} ({{ Number(item.cndtrAge) }})</span>
                      <em>|</em>
                      <span>{{ $bizUtil.dateDashFormat(item.dob) }}</span>
                    </div>
                  </div>
                </div>
                <template v-slot:expand class="ns-gray-box">
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <span class="txtSkip colors">{{ item.dofOrgNm }} {{ item.fofNm }}</span>
                    </div>
                    <div class="list-item__contents__info">
                      <span class="info">후보자등록일</span>
                      <span class="info txtSkip">{{ $bizUtil.dateDashFormat(item.cndtrRegYmd) }}</span>
                    </div>
                    <div class="list-item__contents__info">
                      <span class="info">적격여부</span>
                      <span class="info txtSkip">{{ item.qlfYn }}</span>
                    </div>
                    <div class="list-item__contents__info">
                      <span class="info">추천FC</span>
                      <span class="info txtSkip">{{ item.recmFcNm }}({{ item.recmFcEno }})</span>
                    </div>
                  </div>
                </template>
              </mo-list-item>
            </div>
          </template>
        </mo-list>
      </ur-box-container>
      <!-- 간편정보 아코디언 //-->

      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column">
        <!-- 설문내용 출력 이곳에 인터뷰내용 출력 : 인터뷰 질문지 -->
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-question-area">

          <div v-for="(item, index) in intviListData" :key="item.obtCd">
            <div v-if="index === intviItmIdx">
              <div class="ttl">{{ item.clsfItm }}</div><!-- 항목이름 -->
              <ul class="interview-list">
                  <li class="interview-item">
                    <div class="index">Q{{ index+1 }}.</div><div>{{ item.obtAnsw }}</div><!-- 문항번호/문항타이틀 -->
                  </li>
              </ul>
              <div class="ns-radio-list">
                <mo-radio-wrapper :items="answerData[index].choice" v-model="item.intviValu" @input="fn_SelectEvent(item)"/><!-- 선택지 -->
              </div>
              <div v-show="item.obtCd === '0004' && item.intviValu === '7'" class="flex-item txtarea" label="Count text field">
                <mo-text-area class="last-content" v-model="jobEtcTxt" :maxlength="50" placeholder="최대 50자까지 입력가능합니다." counter="50" />
              </div>
            </div>
          </div>

          <!-- 지점장 의견 -->
          <div v-if="intviListData.length > 0 && intviItmIdx >= intviListData.length">
            <div class="ttl">지점장 의견</div>
            <ul class="interview-list">
                <li class="interview-item">
                  <div class="index">Q{{ intviListData.length+1 }}.</div><!-- 문항번호 -->
                  <div>아래 답변 영역에 지점장 의견을 작성해주세요.</div>
                </li>
            </ul>
            <div class="flex-item txtarea" label="Count text field">
              <mo-text-area class="last-content" v-model="bmngrTxt" :maxlength="50" placeholder="최대 50자까지 입력가능합니다." counter="50"/>
            </div>
          </div>
          <div class="ns-height80"></div>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur_box_container_020" direction="column" class="ns-btn-relative-arrow">
          <div class="relative-div" v-if="intviListData.length > 0 && intviItmIdx === intviListData.length">
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white befor"
                        v-show="intviItmIdx>0" @click="fn_Interview('pre')">이전</mo-button>
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round blue" 
                        v-show="isSubmitBtn || !modalFlag" @click="fn_ModalOpen">작성 완료</mo-button>
          </div>
          <div class="relative-div" v-else>
            <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white befor"
                         @click="fn_Interview('pre')" :style="intviItmIdx<1? 'opacity: 0;': ''" >이전</mo-button>
            <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round next"
                        v-show="isNextBtn" @click="fn_Interview('next')">다음</mo-button>
          </div>
        </ur-box-container>
        <!-- 설문내용 출력 //-->
      </ur-box-container>
    </ur-box-container>

    <!-- 제출/취소 Modal ::start -->
    <div class="ns-bottom-sheet ns-bottom-popup-m">
      <mo-bottom-sheet ref="refAttnPnstaModal">
          <template v-slot:title></template>
          <p class="ns-seminar-txt">
            제출하시겠습니까?
          </p>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button class="ns-btn-round white" @click="fn_Close('C')">취소</mo-button>
                <mo-button class="ns-btn-round blue" @click="fn_Close('I')">제출</mo-button>
              </div>
            </ur-box-container>
          </template>
      </mo-bottom-sheet>
      <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000" />
    </div>
    <!-- 제출/취소 Modal ::end -->
    <!-- Close Confirm Modal ::start -->
    <div class="ns-bottom-sheet ns-bottom-popup-m">
      <mo-bottom-sheet ref="refConfirmModal">
          <template v-slot:title></template>
          <p class="ns-seminar-txt">
            작성중인 화면에서 나가시겠습니까?<br/>
            나가기 선택 시 작성된 내용은 저장되지 않습니다.
          </p>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button class="ns-btn-round white" @click="fn_ConfmModalClose('N')">아니오</mo-button>
                <mo-button class="ns-btn-round blue" @click="closeFn">예</mo-button>
              </div>
            </ur-box-container>
          </template>
      </mo-bottom-sheet>
      <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000" />
    </div>
    <!-- Close Confirm Modal ::end -->
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Screen from '~systems/mixin/screen'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPRC310P',
  screenId: 'MSPRC310P',
  mixins:[Screen],
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right', // 우->좌 open popup (defalut: 하->상)
    beforeClose: true // 타이틀영역 팝업닫기 icon 클릭시 실행
  },
  props: {
    itemInfo: {
      type: Object
    }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_Init()
  },
  destroyed() {
    clearTimeout(this.timerFn) // TimeOut Clear
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_UserInfo: {},     // 사용자 정보

      lv_intviItmList: [], // 인터뷰 입력 response
      intviResult: [],     // 인터뷰 입력 데이터 (질문지/답변지)
      intviListData: [],   // 예외 질문 처리된 데이터
      answerData: [],      // 답변지 데이터
      answerList: [],      // 예외 답변 처리된 데이터
      questionData: [],    // 모든 문항 데이터

      lv_ReturnVal: {},     // 인터뷰 입력값 (callback param)

      intviItmIdx: 0,      // 현재 진행 문항 스텝 index (문항지 스텝별 진행)
      isNextBtn: false,    // 다음 버튼 show/hide 핸들링 변수

      isSubmitBtn: false,  // 작성완료 버튼 show/hide 핸들링 변수
      jobEtcTxt: '', // 직업 기타(input text)
      bmngrTxt: '',   // 지점장 의견(input text)
      closeFn: '',
      timerFn: '',
      isInterval: false, // 문항 답변지 선택시, setTimeOut 중복 호출 방지

      modalFlag: true
    }
  },
  /************************************************************************************************
   * Life Cycle 함수 정의 영역
   ***********************************************************************************************/
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /***********************************************************************************
   * watch 함수 정의 영역	                                                             *
   ***********************************************************************************/
  watch: {
    // 직업 문항의 기타 입력
    jobEtcTxt() {
      if (this.jobEtcTxt === '') {
        this.isNextBtn = false
      } else {
        this.isNextBtn = true
      }
    },
    // 지점장 의견 입력
    bmngrTxt() {
      if (!_.isEmpty(this.bmngrTxt) && this.bmngrTxt.length > 0) {
        this.isSubmitBtn = true
      } else {
        this.isSubmitBtn = false
      }
    }
    
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 진입시, 최초실행 함수
     ******************************************************************************/
    fn_Init() {
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 정보
      this.fn_GetIntviData()
    },
    /******************************************************************************
     * Function명 : fn_GetIntviData
     * 설명       : 인터뷰 질문지/결과지 데이터 조회
    ******************************************************************************/
    fn_GetIntviData() {
      const lv_Vm = this

      let pParams = {
        cnsltEno: this.lv_UserInfo.userId, // 컨설턴트사번
        hqOrgCd: this.itemInfo.hqOrgCd,    // 본부조직코드
        dofOrgCd: this.itemInfo.dofOrgCd,  // 지역단조직코드
        fofOrgCd: this.itemInfo.fofCd,     // 지점조직코드
        parentId: this.itemInfo.parentId,  // 후보자ID
        itmScCd: '2'                       // 항목구분(1:결과 조회 2: 질문지 조회, 3: 결과 입력)
      }

      let trnstId = 'txTSSRC40S2'
      let auth = 'S'

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.intviItmList)) {
            // console.log(response.body)
            let lv_intviItmList = response.body.intviItmList

            lv_Vm.intviResult.push(response.body)
            lv_Vm.chnlScCd = response.body.chnlScCd // 채널구분코드(11:FC, 12:SFP 등)
            // lv_Vm.chnlScCd = '13' // test
            let fcIntviItmList = []
            if (lv_Vm.chnlScCd === '12') { // SFP
              for (let i = 0; i < lv_intviItmList.length; i++) {
                if (lv_intviItmList[i].obtCd !== '0003' && lv_intviItmList[i].obtCd !== '0012') { // 0003:현재 막내 자녀의 학년 및 연령, 0012:육아부담
                  fcIntviItmList.push(lv_intviItmList[i])
                }
              }
            } else { // FC인 경우 '부모님동거', '군필' 질문 항목 안 보임
              for (let i = 0; i < lv_intviItmList.length; i++) {
                if (lv_intviItmList[i].obtCd !== '0020' && lv_intviItmList[i].obtCd !== '0017') { // 0020:부모님 동거, 0017:군필
                  fcIntviItmList.push(lv_intviItmList[i])
                }
              }
            }

            lv_Vm.intviListData = fcIntviItmList // 인터뷰 질문/답변 데이터
            lv_Vm.fn_SetQustionData(fcIntviItmList)
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', '[' + response.msgComm.msgCd + ']\n' + response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
     * Function명 : fn_SetQustionData
     * 설명       : 인터뷰 답변지 데이터 세팅
     *              As-Is 소스 유지
    ******************************************************************************/
    fn_SetQustionData(intviItem) {
      let lv_questionObj = {} // 질문지 데이터 구성
      let lv_qustionObj = {}  // 질문 데이터에 들어갈 object
      let lv_answerObj = {}   // 답변 데이터에 들어갈 object

      // for 반복문 ::start (질문지 / 답변지 데이터 세팅 시작)
      for (let i = 0; i < intviItem.length; i++) {
        // 질문지 데이터 구성
        lv_questionObj = {
          category: '', // 분류명
          clsfCd: '', // 분류코드
          data: []
        }
        lv_questionObj.category = intviItem[i].clsfItm // 분류명
        lv_questionObj.clsfCd = intviItem[i].clsfCd // 분류코드

        lv_qustionObj = { // 질문 데이터에 들어갈 object
          index: 0, // 질문 목록 INDEX
          question: '', // 질문내용
          obtC: '', // 질문코드
          checkbox: false,
          choice: [] // 답변 내용(답변코드 답변내용)
        }

        lv_qustionObj.index = i
        lv_qustionObj.question = intviItem[i].obtAnsw // 질문텍스트
        lv_qustionObj.obtC = intviItem[i].obtCd // 질문코드
        lv_questionObj.data.push(lv_qustionObj)

        this.questionData.push(lv_questionObj) // 질문지 데이터

        lv_answerObj = { // 답변 데이터에 들어갈 object
          key: i, // 답변 목록 INDEX
          checkbox: false,
          choice: [] // 답변보기
        }

        // 답변지 데이터 구성
        let lv_answerList = []
        if (intviItem[i].obtCd === '0001') { // 1번질문(추천 FC와의 관계)
          for (let j = 0; j < intviItem[i].answList.length; j++) {
            if (this.chnlScCd === '12') { // SFP인 경우
              lv_answerList.push(intviItem[i].answList[j])
            } else { // FC인 경우 답변 목록에 없어야 하는 항목들
              if (intviItem[i].answList[j].answCd !== '9' && intviItem[i].answList[j].answCd !== '10' && intviItem[i].answList[j].answCd !== '11') {
                // 9:금융아카데 10:금융영업전문가 과정 11:캠퍼스 리크루팅(취업준비 사이트 等) 제외
                lv_answerList.push(intviItem[i].answList[j])
              }
            }
          } // for ::end
        } else if (intviItem[i].obtCd === '0004') { // 4번질문(현재 직업 또는 과거 경력)
          for (let j = 0; j < intviItem[i].answList.length; j++) {
            if (this.chnlScCd === '12') { // SFP인 경우
              lv_answerList.push(intviItem[i].answList[j])
            } else { // FC인 경우 답변 목록에 없어야 하는 항목들
              if (intviItem[i].answList[j].answCd !== '8' && intviItem[i].answList[j].answCd !== '9') { // 8:재학생, 9: 졸업(졸업예정) 제외
                lv_answerList.push(intviItem[i].answList[j])
              }
            }
          } // for ::end
        } else { // 나머지 질문에 대한 답변 목록
          intviItem[i].answList.forEach((item) => {
            lv_answerList.push(item)
          })
        }

        lv_answerList.forEach((item) => {
          let lv_choiceObj = {
            value: '', // 답변 코드
            key: '', // 답변번호
            text: '' // 답변 내용
          }
          lv_choiceObj.value = item.answCd // 답변코드
          lv_choiceObj.key = item.answCd // 답변 번호
          lv_choiceObj.text = item.answCntnt // 답변 내용
          lv_qustionObj.choice.push(lv_choiceObj) // 질문지 내에 들어가는 답변 목록
          lv_answerObj.choice.push(lv_choiceObj)
        })
        this.answerData.push(lv_answerObj) // 답변지 데이터
      } // for 반복문 ::end (질문지 / 답변지 데이터 세팅 끝)

      // 마지막 질문에 대한 질문 목록 데이터 (평가자의견)
      let lv_input = {category: '', data: []}
      let inputObj = {
        index: this.questionData.length,
        question: '우측 답변영역에 작성해주세요.',
        checkbox: false,
        choice: [{key: '', text: ''}],
        input: true
      }
      lv_input.category = '지점장 의견'
      lv_input.data.push(inputObj)
      this.questionData.push(lv_input)
      // 마지막 질문에 대한 답변 목록 데이터
      let lv_temp = {key: this.answerData.length, checkbox: false, choice: [{key: '', text: ''}], input: true}

      this.answerData.push(lv_temp)
      for (let i = 0; i < this.questionData.length; i++) { // 답변의 KEY값만 담을 답변 ARRAY
        this.answerList.push([])
      }
      // console.log(this.answerList.length)
      // console.log('질문지', this.questionData)
      // console.log('답변지', this.answerData)
    },
    /******************************************************************************
     * Function명 : fn_Interview
     * 설명       : 이전 / 다음 버튼 클릭시 함수 실행
     *              > 이전 / 다음 스텝 문항으로 전환
     *              > Q4 문항 체크 (:현재 직업 또는 과거 경력)
     *                 > 기타 라디오 체크시, 다음 문항으로 안넘어감
     *              > 다음 버튼(show: 텍스트 입력폼 있을시 => Q4의 기타)
     ******************************************************************************/
    fn_Interview(param, item) {
      this.fn_SetLabel(item)
      // 문항4번 기타 라디오 체크 (다음문항으로 바로 안넘어감)
      if (!_.isEmpty(item) && item.obtCd === '0004' && item.intviValu === '7') {
        return
      }

      if (param === 'next' && this.intviItmIdx < this.intviListData.length) {
        this.intviItmIdx = this.intviItmIdx + 1
        let nextItem = this.intviListData[this.intviItmIdx]

        if (!this.$bizUtil.isEmpty(nextItem) && !this.$bizUtil.isEmpty(nextItem.intviValu)) {
          this.isNextBtn = true
        } else {
          this.isNextBtn = false
        }
      } else if (param === 'pre' && this.intviItmIdx > 0) {
        this.isNextBtn = true
        this.intviItmIdx = this.intviItmIdx - 1
      }

      if (this.intviItmIdx === this.intviListData.length) return

      // 문항4번의 기타체크 여부
      let nowQest = this.intviListData[this.intviItmIdx] // 현재 문항
      if (nowQest.obtCd === '0004' && nowQest.intviValu === '7') {
        this.isNextBtn = true
      }
      // } else {
      //   this.isNextBtn = false
      // }
    },
    /******************************************************************************
     * Function명 : fn_SetLabel
     * 설명       : 인터뷰 문항 답변 셋팅
     ******************************************************************************/
    fn_SetLabel(selectItem) {
      if (_.isEmpty(selectItem) || selectItem.answList.lnegth < 1)  return

      selectItem.answList.forEach((item) => {
        if (item.answCd === selectItem.intviValu) {
          selectItem.answCntnt = item.answCntnt
        }
      })
    },
    /******************************************************************************
     * Function명 : fn_GetAnswerList
     * 설명       : 입력한 모든 문항 취합
     *              callback param setting
     ******************************************************************************/
    fn_GetAnswerList() {
      const lv_Vm = this
      this.lv_ReturnVal = {}
      let paramList = this.$bizUtil.cloneDeep(this.intviListData)

      paramList.forEach(item => {
        if (item.obtCd === '0004') {
          if (item.intviValu === '7') {
            item.answCntnt = '기타) '+ lv_Vm.jobEtcTxt
          } else {
            item.answCntnt = item.answCntnt
          }
        }
      })

      paramList.push({
        prgSeq: String(this.intviListData.length+1), // 진행순서
        obtAnsw: '지점장 의견', // 질문내용
        answCntnt: this.bmngrTxt, // 객관식답변내용
      })
      // console.log('paramList', paramList)
      this.lv_ReturnVal = paramList
    },
    /******************************************************************************
     * Function명 : fn_ModalOpen / fn_ModalClose
     * 설명       : 제출확인 Modal Open/Close
     ******************************************************************************/
    fn_ModalOpen()  { this.$refs.refAttnPnstaModal.open()  },
    fn_ModalClose() { this.$refs.refAttnPnstaModal.close() },

    /******************************************************************************
     * Function명 : fn_ConfmModalOpen / fn_ConfmModalClose
     * 설명       : 작성중 팝업닫을 시 안내 Modal Open/Close
     ******************************************************************************/
    fn_ConfmModalOpen(closeFn) {
      this.closeFn = closeFn // before-close 함수 실행
      if (!this.modalFlag) { // 제출시에만, PopUp Close 함수 실행
        closeFn()
        return
      }
      this.$refs.refConfirmModal.open()
    },
    fn_ConfmModalClose() { this.$refs.refConfirmModal.close() },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 팝업 닫기
     *              취소 누르면 모달 close
     ******************************************************************************/
    fn_Close(param) {
      this.fn_ModalClose()

      if (param === 'I') {
        this.fn_GetAnswerList()
        this.modalFlag = false
        // 팝업 입력값 emit
        this.$emit('onPopupSearch', {intviData: this.lv_ReturnVal, parentId: this.itemInfo.parentId})
      }
    },
    /******************************************************************************
     * Function명 : fn_SelectEvent
     * 설명       : 문항 선택시, 0.7초뒤 자동 다음 문항 (0.7초 현업에서 지정)
     *              PM건의사항: 2022.07.20
     ******************************************************************************/
    fn_SelectEvent(item) {
      const lv_Vm = this
      if (this.isInterval) return

      this.isInterval = true
      this.timerFn = setTimeout(function() {
        lv_Vm.fn_Interview('next',item)
        lv_Vm.isInterval = false
      }, '700')
    }
  }
}
</script>