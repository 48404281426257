/*
 * 업무구분: 리크루팅
 * 화 면 명: MSPRC301P
 * 화면 설명: 배양현황 - 상세조회
 * 작 성 일: 2022.06.08
 * 작 성 자: sh.park2022
 */

<template>
  <ur-page-container :show-title="false" title="상세조회" type="popup">
    <ur-box-container direction="column" alignV="start" class="ns-sch-area">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="sch-box">
        <ur-text componentid="span_common_text_001" class="title">후보자명</ur-text>
        <mo-text-field v-model="searchKeyword" underline placeholder="후보자명을 입력하세요." maxlength="10" class="form-input-name"/>
      </ur-box-container>

      <!-- 조직관련 COMPONENT -->
      <SearchDetail ref="refSearchOrg" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData301P" @selectResult="fn_SlectCnsltResultRC"/>

      <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_004" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Clear">초기화</mo-button>
          <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" :disabled="!allChk" @click="fn_Close">조회</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Screen from '~systems/mixin/screen'
import SearchDetail from '@/ui/rc/SearchDetail.vue'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPRC301P',
  screenId: 'MSPRC301P',
  mixins:[Screen],
  components: {
    SearchDetail
  },
  props: {
    title: {
      type: String,
      default: '상세조회'
    },
    pOrgData300M: {
      type: Object,
      name: '최근검색 조직'
    }
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_SelectOrg: [],   // 조직 컴포넌트 데이터
      lv_ReturnVal: {},   // 모든 입력값
      searchKeyword: '',  // 후보자명
      isShowCnslt: false, // 담당컨설턴트
      allChk: false,      // 조직, 담당컨설턴트 조회완료 여부
      pOrgData301P: this.pOrgData300M
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ***********************************************************************************************/
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SlectCnsltResultRC
     * 설명       : 조직 변경시, 전역변수에 셋팅
     *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
     * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
     * allChk: 조회버튼 활성화/비활성화 여부
     ******************************************************************************/
    fn_SlectCnsltResultRC(pData) {
      if (this.isShowCnslt) {
        this.lv_SelectOrg = pData
        this.allChk = true
      }
      this.isShowCnslt = true
    },
    /******************************************************************************
     * Function명 : fn_SetReturnVal
     * 설명       : 팝업 닫힐때, 최종 return 입력값 셋팅
     ******************************************************************************/
    fn_SetReturnVal() {
      if (_.isEmpty(this.lv_SelectOrg)) return

      this.lv_ReturnVal = {
        cndtrNm: this.searchKeyword.trim(),            // 후보자명
        cnsltEno: this.lv_SelectOrg.cnslt.key,  // 컨설턴트 사번
        hofOrgCd: this.lv_SelectOrg.hofOrg.key, // 선택사업부
        dofOrgCd: this.lv_SelectOrg.dofOrg.key, // 선택지역단
        fofOrgCd: this.lv_SelectOrg.fofOrg.key, // 선택지점
        pOrgData: this.lv_SelectOrg             // 조회 조직 데이터
      }
    },
    /******************************************************************************
     * Function명 : fn_Clear
     * 설명       : 입력값 모두 초기화 (초기 값으로 셋팅)
     ******************************************************************************/
    fn_Clear() {
      this.pOrgData301P = {}
      this.$refs.refSearchOrg.fn_GetFofRol() // 초기화후, 다시 조회
      this.searchKeyword = '' // 후보자명
    },
    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : 팝업 닫기
     ******************************************************************************/
    fn_Close() {
      this.fn_SetReturnVal()
      // 팝업 입력값 emit
      this.$emit('onPopupSearch', { OrgData: this.lv_ReturnVal })
    }
  }
}
</script>