/*
 * 업무구분: 리크루팅
 * 화면 명: MSPRC321P
 * 화면 설명: 배양현황 - TS신청 - 캘린더
 * 작 성 일: 2022.06.21
 * 작 성 자: sh.park2022
 */
<template> 
  <ur-page-container title="TS 신청" :show-title="false" type="popup">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-schedule tss">
        <mo-scheduler ref="loginScheduler" :events="tsPnstaList" displayPeriodUom="month" :displayPeriodCount="1"
          @period-changed="fn_PeriodChanged" @click-event="fn_SelectDate" @click-date="fn_SelectDate" :month-picker-props="monthPickerProps"
          :holidays="lv_Holidays" eventTop="30px" eventContentHeight="16px" schedule-detail>
          <!-- 년/월 클릭시 보이는 영역 -->
          <template #month-picker="{year, month}">
            <div>
              {{ year }}년 {{ month }}월
              <mo-icon xsmall>calendar</mo-icon>
            </div>
          </template>
        </mo-scheduler>
      </ur-box-container>
    </ur-box-container>

    <!-- Toast :필요시, 주석풀어서 사용하세요-->
    <!-- <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar> -->
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Screen from '~systems/mixin/screen'
import moment from 'moment'
import MSPRC322P from '@/ui/rc/MSPRC322P.vue' // TS 신청 - 시간별 현황 팝업

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPRC321P',
  screenId: 'MSPRC321P',
  mixins:[Screen],
  components: {
    MSPRC322P
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  props: {
    // 선택 후보자 정보
    itemInfo: {
      type: Object
    }
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_HolidayList: [], // 주말/공휴일 정보를 저장하는 리스트
      lv_Holidays: [], // holidayList 를 파싱하여 만든 객체 리스트.
      startSchdDay: '', // 달력 한 장의 시작일 3월달력의경우 2월마지막주의 첫날(일요일)
      endSchdDay: '', // 달력 한 장의 끝일 3월달력의경우 4월 첫주의 첫날(일요일)
      tsPnstaList: [], // 날짜별 Events
      lv_PriodStart: '', // 해당 월의 첫날
      lv_PeriodEnd: '',  // 해당 월의 마지막날

      monthPickerProps: { minDate: '', maxDate: '' }, // sample) minDate: '2022-03-05', maxDate: '2022-10-30'
      lv_NowLoadingHoliday: false, // 휴일 조회중 여부

      dateObj: {} // 선택 날짜 Object
    }
  },
  /************************************************************************************************
  * watch 함수 정의 영역
  ************************************************************************************************/
  watch: {
    // 휴일 셋팅
    lv_HolidayList() {
      this.lv_Holidays.splice(0, this.lv_Holidays.length)
      for (let i = 0; i < this.lv_HolidayList.length; i++) {
        // 법정공휴일 : 1 , 윤년/음력 : 2
        let tempClass = this.lv_HolidayList[i].slrcYmYmd
        if (this.lv_HolidayList[i].holyCd === '1') {
          this.lv_Holidays.push({date: tempClass, holidayClasses: 'holiday-txt-color', dayNumberClasses: 'holiday-txt-color', holyCd: 1})
        } else if (this.lv_HolidayList[i].holyCd === '2') {
          this.lv_Holidays.push({date: tempClass, holidayClasses: 'holiday-txt-color', dayNumberClasses: 'holiday-txt-color', holyCd: 2})
        }
      }
    }
  },
  /************************************************************************************************
   * Life Cycle 함수 정의 영역
   ***********************************************************************************************/
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 진입시, 최초실행 함수
     ******************************************************************************/
    fn_Init() {
      let dateTime = new Date()
      this.monthPickerProps.minDate = moment(dateTime).format('YYYY-MM-DD')

      dateTime.setMonth(dateTime.getMonth() + 2)
      this.monthPickerProps.maxDate = moment(dateTime).format('YYYY-MM-DD')
      // 건수 조회
      this.fn_SelectHolidayList()
    },
    /******************************************************************************
    * Function명 : fn_PeriodChanged
    * 설명       : 기간이 변경되었을 때 처리하는 Function
    *            : 기간 관련 변수
    *             displayFirstDate - 달력의 첫날
    *             displayLastDate - 달력의 마지막날
    *             lv_PriodStart - 해당 월의 첫날
    *             lv_PeriodEnd - 해당 월의 마지막날
    ******************************************************************************/
    fn_PeriodChanged(date) {
      // 시작하자마자 불리며 이 객체에서 사용할 기본 정보를 세팅함.
      this.startSchdDay = moment(date.displayFirstDate).format('YYYYMMDD')
      this.endSchdDay = moment(date.displayLastDate).format('YYYYMMDD')
      this.lv_PriodStart = moment(date.periodStart).format('YYYYMMDD')
      this.lv_PeriodEnd = moment(date.periodEnd).format('YYYYMMDD')

      // 시작하자마자 불리기 때문에 mounted or created에 작성 불필요, 위에서 세팅된 기본정보를 기반으로 데이터객체를 불러옴.
      this.fn_Init()
    },
    /******************************************************************************
     * Function명: fn_SelectHolidayList
     * 설명: 휴일 목록을 조회한다.
     * Params: N/A
     * Return: N/A
     ******************************************************************************/
    fn_SelectHolidayList() {
      const lv_Vm = this

      if (lv_Vm.lv_NowLoadingHoliday) return

      this.lv_NowLoadingHoliday = true
      lv_Vm.lv_HolidayList = []

      let trnstId = 'txTSSAP03S2'
      let pParam = {
        inqrStrYmd: lv_Vm.startSchdDay,
        inqrEndYmd: lv_Vm.endSchdDay
      }

      lv_Vm.post(lv_Vm, pParam, trnstId, 'S').then(function (response) {
        // console.log(response)

        if (!_.isEmpty(response.body)) {
          let iCYmdMngVO = response.body.iCYmdMngVO
          lv_Vm.iCYmdMngVO = iCYmdMngVO

          if (!_.isEmpty(iCYmdMngVO)) {
            for (let j = 0; j < lv_Vm.iCYmdMngVO.length; j++) {
              let tmpObj = {}
              tmpObj.slrcYmYmd = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.iCYmdMngVO[j].slrcYmYmd)
              tmpObj.holyCd = lv_Vm.iCYmdMngVO[j].holyCd
              tmpObj.holyNm = lv_Vm.iCYmdMngVO[j].holyNm
              lv_Vm.lv_HolidayList.push(tmpObj)
            }
          }

          lv_Vm.lv_NowLoadingHoliday = false
          // TS 신청 현황 조회
          lv_Vm.fn_selListTsPnstaInqr()
        }
      }).catch(function(error) {
        window.vue.error(error)
      })
    },
    /******************************************************************************
     * Function명: fn_selListTsPnstaInqr
     * 설명: 월별 TS일정 조회
     * Params: N/A
     * Return: N/A
     ******************************************************************************/
    fn_selListTsPnstaInqr() {
      const lv_Vm = this

      let trnstId = 'txTSSRC60S1'
      let pPrams = {
        hqOrgCd: lv_Vm.itemInfo.hqOrgCd,   // 본부조직코드
        dofOrgCd: lv_Vm.itemInfo.dofOrgCd, // 지역단조직코드
        fofOrgCd: lv_Vm.itemInfo.fofCd,    // 지점조직코드
        tsAplYm: lv_Vm.lv_PriodStart.substring(0, 6) // TS신청년월
      }

      lv_Vm.post(lv_Vm, pPrams, trnstId, 'S').then(function (response) {
        // console.log(response)

        if (!_.isEmpty(response.body) && !_.isEmpty(response.body.tsPnstaList)) {
          let result = response.body.tsPnstaList

          lv_Vm.tsPnstaList = []
          result.forEach(item => {
            lv_Vm.tsPnstaList.push({
              // * 필수 셋팅 Obj * //
              startDate: moment(item.tsAplYmd).format('YYYY-MM-DD'), // 시작날짜
              endDate: moment(item.tsAplYmd).format('YYYY-MM-DD'),   // 끝날짜
              title: `${item.tsAplNprsn}명`, // 타이틀
              classes: ['blue-event']        // 색상 classes
            })
          })
        }
      }).catch(function (error) {
        window.vue.error(error)
      })
    },
    /*********************************************************
     * Function명: fn_SelectDate
     * 설명: 화면전환을 위해서 사용되거나, 특정일이 선택되었을때실행되는 메서드
     *     @click-event / @click-date 동일하게 적용
     *          > 달력 날짜의 건수 클릭시, click-event 로 호출
     * Params: clickDate: 클릭한 날짜
     * Return: N/A
     *********************************************************/
    fn_SelectDate(clickDate) {
      let paramDate = ''
      if (!this.$bizUtil.isEmpty(clickDate.endDate)) { // @click-event
        paramDate = clickDate.endDate
      } else { // @click-date
        paramDate = clickDate
      }

      let nowDate = moment(new Date()).format('YYYYMMDD') // 오늘날짜
      let dateDay = this.$rcUtil.getDateDay(paramDate)
      let title = moment(paramDate).format('MM월 DD일') + ' ' + dateDay

      this.dateObj = {
        title: title,
        text: moment(paramDate).format('YYYY-MM-DD'),
        value:moment(paramDate).format('YYYYMMDD')
      }

      if (this.dateObj.value < nowDate) {
        // this.$refs.snackbar.show({ text: '이전 날짜는 신청하실 수 없습니다.' }) // Toast (필요시, 주석풀어서 사용하세요)
        return
      }

      this.fn_Open322P()
    },
    /*********************************************************
     * Function명: fn_Open322P
     * 설명: TS신청 - 시간별 현황 팝업 Open (MSPRC322P)
     *       TS신청 완료시, 현재 캘린더팝업도 동시에 Close
     *          > 월별 TS일정 조회X => 바로 해당 날짜에 계산하여 반영됨
     * param: custInfo(선택한 후보자 정보), dateObj(신청 날짜 정보)
     *********************************************************/
    fn_Open322P() {
      const lv_Vm = this

      this.popup322 = this.$bottomModal.open(MSPRC322P,{
        properties: {
          pData:{
            custInfo: this.itemInfo,
            dateObj: this.dateObj // text(YYYY-MM-DD), value(YYYYMMDD)
          }
        },
        listeners: {
          onPopupSearch: (pParam) => {
            this.$bottomModal.close(lv_Vm.popup322)

            // MSPRC322P 팝업에서 취소 눌렀을경우 return
            if (pParam === 'cancle') return
            // console.log('pData', pParam)
            let pnstaObj = {}

            let findObj = lv_Vm.tsPnstaList.filter(item =>{
              return item.startDate === lv_Vm.dateObj.text
            })

            // TS 신청후, TS일정 조회없이 계산하여 적용
            if (findObj.length > 0) {
              lv_Vm.tsPnstaList.forEach(item => {
                if (item.startDate === lv_Vm.dateObj.text) {
                  item.title = Number(item.title.split('명')[0]) + 1 + ' 명'
                }
              })
            } else { // 신청 인원이 없는 날짜라면,
              pnstaObj = {
                  startDate: lv_Vm.dateObj.text, // 시작날짜
                  endDate: lv_Vm.dateObj.text, // 끝날짜
                  title: '1 명', // 타이틀
                  classes: ['blue-event'] // 색상 classes
                }
              lv_Vm.tsPnstaList.push(pnstaObj)
            }

            let pData = {itemInfo: lv_Vm.itemInfo, dateObj: lv_Vm.dateObj}
            this.$emit('onPopupSearch', pData)
          }
        }
      })
    }
  }
}
</script>